import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/biOs_MQTT_Stream_Deck/ForumBox';
import PrimaryBox from 'components/Software/Other_Platforms/biOs_MQTT_Stream_Deck/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Stream Deck biOs MQTT",
  "path": "/Software/Other_Platforms/biOs_MQTT_Stream_Deck/",
  "dateChanged": "2023-05-15",
  "author": "Mike Polinowski",
  "excerpt": "The Elgato Stream Deck, Loupedeck and Razer Stream Controller delivers a unique live content creation controller with endless possibilities to optimize your workflow.",
  "social": "/images/Search/P_SearchThumb_Stream_Deck.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_S-Stream_Deck_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Stream Deck"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Stream Deck biOs MQTT' dateChanged='2023-05-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Elgato Stream Deck, Loupedeck and Razer Stream Controller delivers a unique live content creation controller with endless possibilities to optimize your workflow.' image='/images/Search/P_SearchThumb_Stream_Deck.png' twitter='/images/Search/P_SearchThumb_Stream_Deck.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Andere_Plattform/biOs_MQTT_Stream_Deck/' locationFR='/fr/Software/Other_Platforms/biOs_MQTT_Stream_Deck/' crumbLabel="Stream Deck" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "stream-deck-bios-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#stream-deck-bios-mqtt",
        "aria-label": "stream deck bios mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stream Deck biOs MQTT`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software"
        }}>{`Software`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#other"
            }}>{`Other`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#stream-deck-bios-mqtt"
                }}>{`Stream Deck biOs MQTT`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#where-can-i-download-the-plugins"
                    }}>{`Where can I download the Plugins?`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#your-cameras-rtsp-stream"
                    }}>{`Your Cameras RTSP Stream`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#mqtt-control"
                    }}>{`MQTT Control`}</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p>{`The `}<strong parentName="p">{`Elgato Stream Deck`}</strong>{`, `}<strong parentName="p">{`Loupedeck`}</strong>{` and `}<strong parentName="p">{`Razer Stream Controller`}</strong>{` delivers a unique live content creation controller with endless possibilities to optimize your workflow. Use the `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Vivre_Motion_Stream_Deck/"
      }}>{`VIVRE MOTION Stream Deck IP-CAMERA Plugin`}</a>{` to place the live image from your INSTAR IP Camera directly on a button. The `}<a parentName="p" {...{
        "href": "https://apps.elgato.com/plugins/com.bi0s.mqtt"
      }}>{`biOS MQTT Plugin`}</a>{` allows you take control over your 2k+ WQHD INSTAR IP cameras through it's MQTTv5 Websocket API.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/455b36429042625214845627d6af50da/0f98f/Stream_Deck_biOs_MQTT_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAdclNepiAH//xAAaEAACAwEBAAAAAAAAAAAAAAACAwABEwQh/9oACAEBAAEFAqdsuzIZswoDDLnb4OK7L//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAgIDAQAAAAAAAAAAAAAAAQMRAkEhMlFS/9oACAEBAAY/Ak1HsaxpfNnEkZleT7Eb3Q7W/T//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhQXFRsf/aAAgBAQABPyENhCnMq02ioY5xvSMzKPyOm2Z4xa29J//aAAwDAQACAAMAAAAQMO//xAAWEQADAAAAAAAAAAAAAAAAAAAQETH/2gAIAQMBAT8Qhj//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQIBAT8QDZ//xAAeEAACAgMAAwEAAAAAAAAAAAABEQAhMUFRYXGBof/aAAgBAQABPxB5QElYUjzbmzRhQOKrzqJh5IhFHaYvv2EJIIZwCVeoRPBArdqvw4QSTDAv2f/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/455b36429042625214845627d6af50da/e4706/Stream_Deck_biOs_MQTT_01.avif 230w", "/en/static/455b36429042625214845627d6af50da/d1af7/Stream_Deck_biOs_MQTT_01.avif 460w", "/en/static/455b36429042625214845627d6af50da/7f308/Stream_Deck_biOs_MQTT_01.avif 920w", "/en/static/455b36429042625214845627d6af50da/e1c99/Stream_Deck_biOs_MQTT_01.avif 1380w", "/en/static/455b36429042625214845627d6af50da/76ea5/Stream_Deck_biOs_MQTT_01.avif 1840w", "/en/static/455b36429042625214845627d6af50da/f696c/Stream_Deck_biOs_MQTT_01.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/455b36429042625214845627d6af50da/a0b58/Stream_Deck_biOs_MQTT_01.webp 230w", "/en/static/455b36429042625214845627d6af50da/bc10c/Stream_Deck_biOs_MQTT_01.webp 460w", "/en/static/455b36429042625214845627d6af50da/966d8/Stream_Deck_biOs_MQTT_01.webp 920w", "/en/static/455b36429042625214845627d6af50da/445df/Stream_Deck_biOs_MQTT_01.webp 1380w", "/en/static/455b36429042625214845627d6af50da/78de1/Stream_Deck_biOs_MQTT_01.webp 1840w", "/en/static/455b36429042625214845627d6af50da/882b9/Stream_Deck_biOs_MQTT_01.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/455b36429042625214845627d6af50da/e83b4/Stream_Deck_biOs_MQTT_01.jpg 230w", "/en/static/455b36429042625214845627d6af50da/e41a8/Stream_Deck_biOs_MQTT_01.jpg 460w", "/en/static/455b36429042625214845627d6af50da/4d836/Stream_Deck_biOs_MQTT_01.jpg 920w", "/en/static/455b36429042625214845627d6af50da/89b2d/Stream_Deck_biOs_MQTT_01.jpg 1380w", "/en/static/455b36429042625214845627d6af50da/5726f/Stream_Deck_biOs_MQTT_01.jpg 1840w", "/en/static/455b36429042625214845627d6af50da/0f98f/Stream_Deck_biOs_MQTT_01.jpg 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/455b36429042625214845627d6af50da/4d836/Stream_Deck_biOs_MQTT_01.jpg",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "where-can-i-download-the-plugins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#where-can-i-download-the-plugins",
        "aria-label": "where can i download the plugins permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where can I download the Plugins?`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stream Deck`}</strong>{`: Open your Stream Deck Software and go to the Stream Deck Store.`}</li>
      <li parentName="ul"><strong parentName="li">{`Loupedeck`}</strong>{`: Open your Loupedeck Software and go to the Marketplace.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/121ef36006180cf312f4650d21adbf47/01a87/Stream_Deck_biOs_MQTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA+klEQVQY022Q0U6EMBBF+RXZQtuZdroVUiiB8Dnrw1KhwcT49WqriNHzNLfJye1Mcbs9xRj3fV/XNfxhCeH1fn9bnpf4EsKybVuMcUuEEIq6rvreD94DAPumyjB2qSpTPjyyioFml1IIoZSqOS8TxTVhrdVaq0QejohEZK29GiLy3s/z3DQNIiqlCp0gIgCQUgIAIuYhvyAAEWlNCrFt23EcrbVCCCnljyylFAkpJeccAI6YZI2ITdMMw/CPjIi5DRNKqSMesnOu7/uu6wyREOJXM+c8tzHG8sA5Pzc75zrnPpdJfMnnaxljpmmy1uZ4Pso8z34Y8p8/5HdudmK9hoWyUAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/121ef36006180cf312f4650d21adbf47/e4706/Stream_Deck_biOs_MQTT_02.avif 230w", "/en/static/121ef36006180cf312f4650d21adbf47/d1af7/Stream_Deck_biOs_MQTT_02.avif 460w", "/en/static/121ef36006180cf312f4650d21adbf47/7f308/Stream_Deck_biOs_MQTT_02.avif 920w", "/en/static/121ef36006180cf312f4650d21adbf47/b4410/Stream_Deck_biOs_MQTT_02.avif 1288w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/121ef36006180cf312f4650d21adbf47/a0b58/Stream_Deck_biOs_MQTT_02.webp 230w", "/en/static/121ef36006180cf312f4650d21adbf47/bc10c/Stream_Deck_biOs_MQTT_02.webp 460w", "/en/static/121ef36006180cf312f4650d21adbf47/966d8/Stream_Deck_biOs_MQTT_02.webp 920w", "/en/static/121ef36006180cf312f4650d21adbf47/6f638/Stream_Deck_biOs_MQTT_02.webp 1288w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/121ef36006180cf312f4650d21adbf47/81c8e/Stream_Deck_biOs_MQTT_02.png 230w", "/en/static/121ef36006180cf312f4650d21adbf47/08a84/Stream_Deck_biOs_MQTT_02.png 460w", "/en/static/121ef36006180cf312f4650d21adbf47/c0255/Stream_Deck_biOs_MQTT_02.png 920w", "/en/static/121ef36006180cf312f4650d21adbf47/01a87/Stream_Deck_biOs_MQTT_02.png 1288w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/121ef36006180cf312f4650d21adbf47/c0255/Stream_Deck_biOs_MQTT_02.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Search for SMART HOME `}<strong parentName="p">{`Surveillance IP Camera`}</strong>{` and `}<strong parentName="p">{`biOs MQTT`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/644812e716412a659dbd66f89247802d/71b12/Stream_Deck_biOs_MQTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABJ0AAASdAHeZh94AAABG0lEQVQoz42RvU7DMBSFs0bKwpIfJ6GhdWzHsYsYuoASVWoRSGVg4CEYYOBtypQfJY/Bsx0UV0HtUJrh05Wsq+8c+Vr7/Te6rkNd1ydUVTWJpmnQtq2h73tYlFJEUQTbtk9wHGcSnuchCHwQQpCmKSwpc3DOkGUSS30LpRWU1ojjeBJDmWH6vg+lFCytNQQX0HcSTCUgJPxbCILAJF8iDEMjzvMc1iCbLSKUize8sE/kOjNJnAskSXJWOr6PcyggpYSl8yVuOMEu/sI7r7F5WuNxu0FZ3EMIbhbPNTsOc133IOScYzjMLLkG4xRlUaBcb/FQPoOm4l/hsXQ4zqGh1mCMGanIJOg8xHz1Abr7QSxfQbwrBCS8+I+j8BcaO/nZdqiw8QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/644812e716412a659dbd66f89247802d/e4706/Stream_Deck_biOs_MQTT_03.avif 230w", "/en/static/644812e716412a659dbd66f89247802d/d1af7/Stream_Deck_biOs_MQTT_03.avif 460w", "/en/static/644812e716412a659dbd66f89247802d/7f308/Stream_Deck_biOs_MQTT_03.avif 920w", "/en/static/644812e716412a659dbd66f89247802d/f0321/Stream_Deck_biOs_MQTT_03.avif 1308w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/644812e716412a659dbd66f89247802d/a0b58/Stream_Deck_biOs_MQTT_03.webp 230w", "/en/static/644812e716412a659dbd66f89247802d/bc10c/Stream_Deck_biOs_MQTT_03.webp 460w", "/en/static/644812e716412a659dbd66f89247802d/966d8/Stream_Deck_biOs_MQTT_03.webp 920w", "/en/static/644812e716412a659dbd66f89247802d/5c7ba/Stream_Deck_biOs_MQTT_03.webp 1308w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/644812e716412a659dbd66f89247802d/81c8e/Stream_Deck_biOs_MQTT_03.png 230w", "/en/static/644812e716412a659dbd66f89247802d/08a84/Stream_Deck_biOs_MQTT_03.png 460w", "/en/static/644812e716412a659dbd66f89247802d/c0255/Stream_Deck_biOs_MQTT_03.png 920w", "/en/static/644812e716412a659dbd66f89247802d/71b12/Stream_Deck_biOs_MQTT_03.png 1308w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/644812e716412a659dbd66f89247802d/c0255/Stream_Deck_biOs_MQTT_03.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/844cc/Stream_Deck_biOs_MQTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABJ0AAASdAHeZh94AAABG0lEQVQoz42RS07CUBSGuwGCEyh9APZ1e3vbIpbWUYMRjEOjG3FDxBFtSNiFK/vMraGOeAy+nNxHvpzzH2O3++ZwONA0DW3TdHW/1+xvom3bnuPxiBHHMfP5nMFg0DMcDm/GcRwmk0lXi6LASBKFEAIpJVmeoZRiOp3iuu5NaJH+Px6PqaoKY5EvkDLmYVmQpBm2bWNZVo8+X0NLdS3LEkPGMX4QslQB1UKg0gwRRXie10VxTvp/b3VC0zRZrVYYWZYRhIJ1KfnYljy/bCiKR5SS+L53scvTm+PYjEajP6FeShgEzO59QpFQ1zWb7Rv1+pUwEl3gl8bVUt1hL8zzvFtKFIYolRIFLv7TF+L9h1n6iWXeYdvO1QxPwl/+I/oXgfA4twAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b04a54acc1d67f32855ec2ed2c6ae507/e4706/Stream_Deck_biOs_MQTT_04.avif 230w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/d1af7/Stream_Deck_biOs_MQTT_04.avif 460w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/7f308/Stream_Deck_biOs_MQTT_04.avif 920w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/ff569/Stream_Deck_biOs_MQTT_04.avif 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b04a54acc1d67f32855ec2ed2c6ae507/a0b58/Stream_Deck_biOs_MQTT_04.webp 230w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/bc10c/Stream_Deck_biOs_MQTT_04.webp 460w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/966d8/Stream_Deck_biOs_MQTT_04.webp 920w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/83135/Stream_Deck_biOs_MQTT_04.webp 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b04a54acc1d67f32855ec2ed2c6ae507/81c8e/Stream_Deck_biOs_MQTT_04.png 230w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/08a84/Stream_Deck_biOs_MQTT_04.png 460w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/c0255/Stream_Deck_biOs_MQTT_04.png 920w", "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/844cc/Stream_Deck_biOs_MQTT_04.png 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b04a54acc1d67f32855ec2ed2c6ae507/c0255/Stream_Deck_biOs_MQTT_04.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "your-cameras-rtsp-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#your-cameras-rtsp-stream",
        "aria-label": "your cameras rtsp stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your Cameras RTSP Stream`}</h4>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "/en/Software/Other_Platforms/Vivre_Motion_Stream_Deck/"
        }}>{`Adding your camera to the VIVRE MOTION Stream Deck IP-CAMERA Plugin`}</a></p>
    </blockquote>
    <p>{`Every INSTAR HD/FullHD/WQHD IP Camera supports the RTSP streaming protocol. The Real Time Streaming Protocol (RTSP) is an application-level network protocol designed for multiplexing and packetizing multimedia transport streams (such as interactive media, video and audio) over a suitable transport protocol.`}</p>
    <p><strong parentName="p">{`Video Streaming`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
        }}>{`IN-9408 WQHD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9020_HD/Video_Streaming/"
        }}>{`IN-9020 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9010_HD/Video_Streaming/"
        }}>{`IN-9010 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9008_HD/Video_Streaming/"
        }}>{`IN-9008 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
        }}>{`IN-8015 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8003_HD/Video_Streaming/"
        }}>{`IN-8003 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8001_HD/Video_Streaming/"
        }}>{`IN-8001 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-7011_HD/Video_Streaming/"
        }}>{`IN-7011 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5907_HD/Video_Streaming/"
        }}>{`IN-5907 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5905_HD/Video_Streaming/"
        }}>{`IN-5905 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6014_HD/Video_Streaming/"
        }}>{`IN-6014 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/Video_Streaming/"
        }}>{`IN-6012 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6001_HD/Video_Streaming/"
        }}>{`IN-6001 HD`}</a></li>
    </ul>
    <p>{`Add the RTSP streaming URL here to use the VLC Player to display your camera's live video:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b57c24be359c7419196759fd330840d0/ea964/Stream_Deck_biOs_MQTT_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABJ0AAASdAHeZh94AAAA5klEQVQY01WQ23KEIBBE/RcXRJCLFzDiZeNuSv8pT/nzTs1s3Ko8dDEFw5meLpqmgTEGXdchpoSh7zGEAO896M1ay6LaOfeu6Q/VKSU4ZyGlRN/3KK5GKQQe+yfGnOH3J2KMWJaFm0jDMLCuQVprvh/HEUopCCEQQngBaVJd1zi+Hoj3Oz6+f5BiRJ4m5JwxzzM7mabMUHJHQFkpVFWF2+2GsixZxbUCneZvlUYpBhBs33fWuq4cy9WrtUHnG7RtYIcEprXfDv/lZS3atsW2bTjPE8dxMJTuruy0MXCmguf8Xi6FkPgFlgOcmzp0Ab4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b57c24be359c7419196759fd330840d0/e4706/Stream_Deck_biOs_MQTT_05.avif 230w", "/en/static/b57c24be359c7419196759fd330840d0/d1af7/Stream_Deck_biOs_MQTT_05.avif 460w", "/en/static/b57c24be359c7419196759fd330840d0/7f308/Stream_Deck_biOs_MQTT_05.avif 920w", "/en/static/b57c24be359c7419196759fd330840d0/2a334/Stream_Deck_biOs_MQTT_05.avif 1312w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b57c24be359c7419196759fd330840d0/a0b58/Stream_Deck_biOs_MQTT_05.webp 230w", "/en/static/b57c24be359c7419196759fd330840d0/bc10c/Stream_Deck_biOs_MQTT_05.webp 460w", "/en/static/b57c24be359c7419196759fd330840d0/966d8/Stream_Deck_biOs_MQTT_05.webp 920w", "/en/static/b57c24be359c7419196759fd330840d0/06170/Stream_Deck_biOs_MQTT_05.webp 1312w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b57c24be359c7419196759fd330840d0/81c8e/Stream_Deck_biOs_MQTT_05.png 230w", "/en/static/b57c24be359c7419196759fd330840d0/08a84/Stream_Deck_biOs_MQTT_05.png 460w", "/en/static/b57c24be359c7419196759fd330840d0/c0255/Stream_Deck_biOs_MQTT_05.png 920w", "/en/static/b57c24be359c7419196759fd330840d0/ea964/Stream_Deck_biOs_MQTT_05.png 1312w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b57c24be359c7419196759fd330840d0/c0255/Stream_Deck_biOs_MQTT_05.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now add the RTSP Stream of your camera with your camera login, the camera IP or DDNS address, the streaming URL and RTSP Port. E.g. if your camera is configured as follows and you want to access the medium video stream:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera Username`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code></li>
      <li parentName="ul"><strong parentName="li">{`Camera Login`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`abcdefgh`}</code></li>
      <li parentName="ul"><strong parentName="li">{`IP Address`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`80.152.227.129`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Port`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`554`}</code>{` `}<em parentName="li">{`Standard Port`}</em></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Streaming URL`}</strong>{`: 2k+ WQHD models `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/livestream/12`}</code>{` / Full HD & HD models `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/12`}</code></li>
    </ul>
    <p>{`You will have to use the following in both the `}<strong parentName="p">{`URL`}</strong>{` and `}<strong parentName="p">{`Parameter`}</strong>{` input field: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://admin:abcdefgh@80.152.227.129:554/livestream/12`}</code>{`. For debugging purposes it is recommended to try to `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`open your camera stream inside the VLC player`}</a>{` first.`}</p>
    <p>{`The `}<strong parentName="p">{`EXE Path`}</strong>{` con be pointed to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vlc.exe`}</code>{` to be able to access the full screen video. Add a `}<strong parentName="p">{`Close`}</strong>{`  button to be able to close the player from your Stream Deck:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf94112edeb334e6ab976ba9b9986d8c/5caea/Stream_Deck_biOs_MQTT_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB+0lEQVQoz11RS2sUQRDem4iiiZhg1CRmZ/pVr57p7pnZ2V0JGxITWB8JIQS9iV7EgOBRb7l6EwRvCt70qv9PZjYB8as6FfU9umtQlmFvbzelRERIZL2AsBUBEdc1A6MvKx8bJgwh1D1ijEQ08L6YTKYpxjLFhyE8zdTMurkyj7SdK7PryhkUKF7EE2Jd1+14XFWViBhjBtQB1TArp+3b0ejPlRvnaxvfrt/6cuf+99XNn0sPPq/qYVF4IkSMMYYQlFJaa2vtgJmJqCyK4xfPT5rR+d2Nl5n+cG/jTabfZ7oxUjkhEWFGAARwzuV5PhwOsywbiAgAjEftq7Oz1/Xo19Wlj2vrP26ufLq99nV5JbdGO9tz2SIa5whRKXVB5kuIMCI6AMTOw4Fz0BUCLhZqa8UYbe3W1tb/ZK01ABitwTmV585aZzs450S65AdKjZXKjBkO/yMTyaVKCOFgf997L12ci7H0sZUxzto8y/4hE5Ux+pREBIm4KKq6JmbsL98J9BIIMJ1O27ZdJLr4bWSeeH/AIt575kQUECuRRadLcyJKKbVt60UWzxl45tz7d+ubv68tjxAfW3sY4+lkcpjSUVUdVtVJWc4AnAgTFUXRNE2M0fXo78zcAMyMCURHWs9ZTtv2WVk+8X7u/THirrVOBAF2dna2t7dDCAvnv06eh7kIX3e8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf94112edeb334e6ab976ba9b9986d8c/e4706/Stream_Deck_biOs_MQTT_06.avif 230w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/d1af7/Stream_Deck_biOs_MQTT_06.avif 460w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/7f308/Stream_Deck_biOs_MQTT_06.avif 920w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/c91b9/Stream_Deck_biOs_MQTT_06.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf94112edeb334e6ab976ba9b9986d8c/a0b58/Stream_Deck_biOs_MQTT_06.webp 230w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/bc10c/Stream_Deck_biOs_MQTT_06.webp 460w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/966d8/Stream_Deck_biOs_MQTT_06.webp 920w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/ef2bd/Stream_Deck_biOs_MQTT_06.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf94112edeb334e6ab976ba9b9986d8c/81c8e/Stream_Deck_biOs_MQTT_06.png 230w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/08a84/Stream_Deck_biOs_MQTT_06.png 460w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/c0255/Stream_Deck_biOs_MQTT_06.png 920w", "/en/static/cf94112edeb334e6ab976ba9b9986d8c/5caea/Stream_Deck_biOs_MQTT_06.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf94112edeb334e6ab976ba9b9986d8c/c0255/Stream_Deck_biOs_MQTT_06.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "mqtt-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mqtt-control",
        "aria-label": "mqtt control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Control`}</h4>
    <p>{`Your INSTAR 2k+ WQHD camera now supports a Websocket tunnel for the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTT interface`}</a>{`. This means that you can control your camera with MQTT without the need of a smarthome system. Make sure that you activate the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`Websocket (WS) service and use the Websocket (WS) server port in the following steps (default 1885)`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Start by dragging a `}<strong parentName="p">{`Multi-Action Button`}</strong>{` onto your deck:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/16619c06da92730bd554c83c5f54f2cd/d073d/Stream_Deck_biOs_MQTT_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABJ0AAASdAHeZh94AAACWklEQVQ4y12TO28TQRDH3SMhK3IgceL43rd7u/f22/gRIShIixRRJFICXQqUAmhoKCIECCXId7aTfAK+Bd/sj2adNQnFaG7ndn7z3MrXbz9xeTXDrFhiVi5RlNcoiyXKcomyWKAoFg91eYNifosZfc+vMV/coFzcqO9fszkqjPl4NhxCSgHDMLBnNLHt2NixLdSVttW5btvYsi1Yjg+XhzDNJhzHQcA5PM+FZVkwTROVJEnQ6/WRZxna3S5ejfZxvstx7EV43zBw4ng4bxh453j40LQRxTHiOEYYhmi32xiPx+j1eiBOEASoSClB4joO8m4bryfPcbth4vNegKtqDV8aJpa7Bn5s1vH7cQ0yDJUIIRRkMBggz3OkaQrGGCoUiYBJnODo9ASHL17i4omBM8PFxdMdnDUtfN9qQHAOgzOVGQllQyDKkECe54FzvgJStDzLcXT6FofjCebVGj7tNnFZ28bHegNFdRMp4/DYQyCVPJ1OEUWRgiqgKvnukm1ZcH0fDc5hCwGDMTikOQcPAuWkgeSnoZ1OR8EelEyaDCIIwH0fkvS9c/AfkM40jMlkijxvrf9XNEwKgVAIBRfU0yTBwcEBWq3WOuD94ASgzEajkcqSJr8GEixKU0T9HtIsVc0mIPX2vtBd6pfeDD0YHXRdchBKDMMIx1yglSRI0hWUMiCdZZkSCqKB/9ZmqGy+76+GEkkJO47wxnbx59EGxoyDhSGyNEW/30e321WOtGcapjeDMiSb67rq1ZCs1kZK5FGM/SxHdOdAF3XfdLlatM33mYLSDpLQ8/sLf/+rhESOSUgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16619c06da92730bd554c83c5f54f2cd/e4706/Stream_Deck_biOs_MQTT_07.avif 230w", "/en/static/16619c06da92730bd554c83c5f54f2cd/d1af7/Stream_Deck_biOs_MQTT_07.avif 460w", "/en/static/16619c06da92730bd554c83c5f54f2cd/7f308/Stream_Deck_biOs_MQTT_07.avif 920w", "/en/static/16619c06da92730bd554c83c5f54f2cd/02783/Stream_Deck_biOs_MQTT_07.avif 1297w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/16619c06da92730bd554c83c5f54f2cd/a0b58/Stream_Deck_biOs_MQTT_07.webp 230w", "/en/static/16619c06da92730bd554c83c5f54f2cd/bc10c/Stream_Deck_biOs_MQTT_07.webp 460w", "/en/static/16619c06da92730bd554c83c5f54f2cd/966d8/Stream_Deck_biOs_MQTT_07.webp 920w", "/en/static/16619c06da92730bd554c83c5f54f2cd/37e8d/Stream_Deck_biOs_MQTT_07.webp 1297w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16619c06da92730bd554c83c5f54f2cd/81c8e/Stream_Deck_biOs_MQTT_07.png 230w", "/en/static/16619c06da92730bd554c83c5f54f2cd/08a84/Stream_Deck_biOs_MQTT_07.png 460w", "/en/static/16619c06da92730bd554c83c5f54f2cd/c0255/Stream_Deck_biOs_MQTT_07.png 920w", "/en/static/16619c06da92730bd554c83c5f54f2cd/d073d/Stream_Deck_biOs_MQTT_07.png 1297w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/16619c06da92730bd554c83c5f54f2cd/c0255/Stream_Deck_biOs_MQTT_07.png",
              "alt": "Stream Deck biOs MQTT",
              "title": "Stream Deck biOs MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the `}<strong parentName="p">{`Content`}</strong>{` page and add the first of `}<em parentName="p">{`up to 4`}</em>{` `}<strong parentName="p">{`MQTT Buttons`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/893afb0d457f5d7dd6545151e712b6be/5f652/Stream_Deck_biOs_MQTT_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABJ0AAASdAHeZh94AAACIUlEQVQ4y42TO4/TQBSF/RN2JQoKhB1vEo89Ho/fiZ3E3gIUIu1WFEggLUpJQQ8SBdm8NtIWtBT8DBYQ/LeD7k0cdtOE4tM8PHPm+Mwd48PHT5hdL7Bab7BcbbBcrjG/+YL5+hbX8wVm8yWu96zuscRiebPds9pw//NsAcO2bahAIwxDKKUQa4mzbIJOOIBtPkGrZT3E2ramaaLdbsMVAq1WizHNpzB00oM//Qrx/g/sd7/waPoTJ1ffcXp1h9O3Pw64w8n0Nx6/+gYZRBiUJaq6hud5jJQSRhhqPJ9cIh+/RnD+EnY6gRWPYSXjbXtI8gKd9BlcT6Lf72M0GsH3fQgh4LoujEj7uKg1zod99BIN6dgQbQuiY23b++zmpGizGxLK8xxpmrAgYQRKIY0CztGTPhzhHkGwO3JTVRXqukaapjxmQTql6wg4jrO3fYwmM3JXFAWyLOML3Qs2C8XulGPQWil9/m0SjaLoocN/J7v/71BKJFqilyV7sb0gnaSjGFRCaZoh1JoDP6SpVep7noT0BBTfsMs1SRhK+ZC+QhF7uCzOkOjtmDb3ej0OPI5jhvpBELAouaQ5WkNz3W6X78Hgj1JiVCR4czFEGpM7xZuHwyFDoVNO5WCALM/5jwhyREL02hoM2kwuPanQdRW7o1KisKuqRlmWXMBEkiTQuzgOBZvnZzSZUBvs+lEcsxDVGJUFQWMSIyFa2wjSRTSC5PAvEZHG2Ynz1RQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/893afb0d457f5d7dd6545151e712b6be/e4706/Stream_Deck_biOs_MQTT_08.avif 230w", "/en/static/893afb0d457f5d7dd6545151e712b6be/d1af7/Stream_Deck_biOs_MQTT_08.avif 460w", "/en/static/893afb0d457f5d7dd6545151e712b6be/7f308/Stream_Deck_biOs_MQTT_08.avif 920w", "/en/static/893afb0d457f5d7dd6545151e712b6be/7067b/Stream_Deck_biOs_MQTT_08.avif 1302w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/893afb0d457f5d7dd6545151e712b6be/a0b58/Stream_Deck_biOs_MQTT_08.webp 230w", "/en/static/893afb0d457f5d7dd6545151e712b6be/bc10c/Stream_Deck_biOs_MQTT_08.webp 460w", "/en/static/893afb0d457f5d7dd6545151e712b6be/966d8/Stream_Deck_biOs_MQTT_08.webp 920w", "/en/static/893afb0d457f5d7dd6545151e712b6be/03522/Stream_Deck_biOs_MQTT_08.webp 1302w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/893afb0d457f5d7dd6545151e712b6be/81c8e/Stream_Deck_biOs_MQTT_08.png 230w", "/en/static/893afb0d457f5d7dd6545151e712b6be/08a84/Stream_Deck_biOs_MQTT_08.png 460w", "/en/static/893afb0d457f5d7dd6545151e712b6be/c0255/Stream_Deck_biOs_MQTT_08.png 920w", "/en/static/893afb0d457f5d7dd6545151e712b6be/5f652/Stream_Deck_biOs_MQTT_08.png 1302w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/893afb0d457f5d7dd6545151e712b6be/c0255/Stream_Deck_biOs_MQTT_08.png",
              "alt": "Stream Deck biOs MQTT",
              "title": "Stream Deck biOs MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As seen above you first have to add your camera's IP address, `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`WS Server Port and Broker Username and Password`}</a>{`. Then scroll down to the add the `}<strong parentName="p">{`MQTT Command Topic`}</strong>{` you want to toggle:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb9008c044a50aed0c2df512656ce1a4/ffaa5/Stream_Deck_biOs_MQTT_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.1304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABJ0AAASdAHeZh94AAACRklEQVQ4y4WS204UQRCG5xmMoC4E3Nk59WmY3jntsuzMBrww3hCjN4QYTYyAd975HkaNiVH2BMvhIX9ThUPWiPHiT810V339V3U7nz5/w/efE0zmFxjPSAuM59cYzy5xOl38pfH0AmOKkzNMZueYzhaYzmn9DD9Op3B8z0M3FkjjEFb5KIyLIHsCaRIE6/fgbzyAv7H6pzYfot1+DN/3IYWA53kgjuu24cR5Bf36K8TRNby3V3j06hL3D6+weniJlYNzrBwslnTOe62XX6C2MhR5hqqqoZSCEAJSSjjdrED94j2S5x8hnn3AWn2M1vAdWtXR3apPsFm9QaQM+r0eRqMRA6MoYqiTW439SqHupyitgPLWIdw1yM4/5Lagg02GKK1RFAWyLGMgybFbBoOyC5tm0CZGGAkEkeB4l2hPSGpRotfroaoq5HnO7TJQKY0gCBGG4a3t/4mKCbi93cdwOGSgMeYGqLW+s6hpYVnNOkWtFchMXhTIs+x2jgyMOPHGZeN02e0yrPkmmI0V+mXOs6R6vhQCUguJpTnmSNOU7UupeJ2Hr26+4zgG5fO/UtAyQmwUwjDiN0nv0aFirQ2yRKNvQ9hkCyaOkSQJz6bb7cJay6LDCNocSDllWXIMgoDFQCE1nlYWx/sp0sRwOwQaDAbY2dnhIhKtUT6pmRk5JlCn02H9dqhh4gQmydktnUiwuh5hd3eXtbe3xy4J1AAJQG222+1b8QwbKN0cRWqrLAp2RY+WRO7o3dEYCEZ5BBNSMphgruviFxLGzrTw+HXdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb9008c044a50aed0c2df512656ce1a4/e4706/Stream_Deck_biOs_MQTT_09.avif 230w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/d1af7/Stream_Deck_biOs_MQTT_09.avif 460w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/7f308/Stream_Deck_biOs_MQTT_09.avif 920w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/fec71/Stream_Deck_biOs_MQTT_09.avif 1299w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb9008c044a50aed0c2df512656ce1a4/a0b58/Stream_Deck_biOs_MQTT_09.webp 230w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/bc10c/Stream_Deck_biOs_MQTT_09.webp 460w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/966d8/Stream_Deck_biOs_MQTT_09.webp 920w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/937bc/Stream_Deck_biOs_MQTT_09.webp 1299w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb9008c044a50aed0c2df512656ce1a4/81c8e/Stream_Deck_biOs_MQTT_09.png 230w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/08a84/Stream_Deck_biOs_MQTT_09.png 460w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/c0255/Stream_Deck_biOs_MQTT_09.png 920w", "/en/static/fb9008c044a50aed0c2df512656ce1a4/ffaa5/Stream_Deck_biOs_MQTT_09.png 1299w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb9008c044a50aed0c2df512656ce1a4/c0255/Stream_Deck_biOs_MQTT_09.png",
              "alt": "Stream Deck biOs MQTT",
              "title": "Stream Deck biOs MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTT interface`}</a>{` gives you access to every camera function. To use any of these command topics all you have to do is to take your camera client prefix and ID - as configured in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT Client Configuration`}</a>{` - and prepend them to the command topic you want to toggle.`}</p>
    <p><strong parentName="p">{`For Example:`}</strong></p>
    <p>{`You want to toggle the first `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Multimedia/Privacy_Mask/"
      }}>{`Privacy Area`}</a>{`. The command topic for it is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`multimedia/privacy/region1/enable`}</code>{`. We can use to RAW version of it here `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`multimedia/privacy/region1/enable/raw`}</code>{` - this means that the message we have to send to either turn the privacy mask on or off is a single `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`.`}</p>
    <p>{`If you configured the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT Client`}</a>{` in your camera to use the `}<strong parentName="p">{`MQTT Topic Prefix`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`MQTT Client ID`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`115`}</code>{` you have to use the following command topics with the message payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` to toggle your 4 privacy areas:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/115/multimedia/privacy/region1/enable/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/115/multimedia/privacy/region2/enable/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/115/multimedia/privacy/region3/enable/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/115/multimedia/privacy/region4/enable/raw`}</code></li>
    </ul>
    <p>{`The `}<strong parentName="p">{`Multi-Action Button`}</strong>{` allows you to add `}<em parentName="p">{`up to 4`}</em>{` `}<strong parentName="p">{`MQTT Buttons`}</strong>{`. Add all of them with the message payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` to the first stage and message payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` to the second. Now you are able to activate all areas with a single button press and deactivate them again with the same button:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/103a163a00d0042ef92bc2c6a000a08d/Stream_Deck_biOs_MQTT_10.gif",
        "alt": "Stream Deck biOs MQTT"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      